// React
import { ReactElement } from 'react';
// Next
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Button } from '@utmb/design-system/button/Button';
// Styles
import styles from './section500.module.scss';

export const Section500 = (): ReactElement => {
    const router = useRouter();
    const { t } = useTranslation();

    const headingFirstClasses = ['lh-38 lh-d-80', styles.heading].filter(Boolean).join(' ');
    const headingSecondClasses = ['lh-48 lh-d-95', styles.heading, styles.heading_second].filter(Boolean).join(' ');
    const summaryClasses = ['lh-24 lh-d-32', styles.summary].filter(Boolean).join(' ');
    return (
        <section className={styles.root}>
            <div className={styles.container}>
                <div className={styles.left_section}>
                    <div className={styles.content_wrapper}>
                        <div className={styles.heading_wrapper}>
                            <Font.FuturaMedium mobile="40" desktop="80" className={headingFirstClasses}>
                                {t('page500.headingFirst')}
                            </Font.FuturaMedium>
                            <Font.FuturaMedium mobile="40" desktop="80" className={headingSecondClasses}>
                                {t('page500.headingSecond')}
                            </Font.FuturaMedium>
                        </div>
                        <div className={styles.summary_cta_wrapper}>
                            <Font.FuturaMedium mobile="16" desktop="22" className={summaryClasses}>
                                {t('page500.summary')}
                            </Font.FuturaMedium>
                            <Button.Default className={styles.cta} onPress={() => router.push('/')} label={t('page500.cta')}></Button.Default>
                        </div>
                    </div>
                </div>
                <div className={styles.right_section}>
                    <Image
                        src="https://res.cloudinary.com/livetrailtest/image/upload/v1/UTMBMontBlanc/Menus/navigation_photos_69f5b69484?_a=ATADJAA0"
                        alt="lost runner"
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
            </div>
        </section>
    );
};
