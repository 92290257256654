import { GetServerSideProps, NextPage } from 'next';
import { ReactElement } from 'react';
import { PageProviders } from '@utmb/contexts/index';
import { Layout } from '@utmb/layouts/layout-base';
import { Section500 } from '@utmb/features/section-500/Section500';
import { PageDefaultProps } from '@utmb/types/page';
import { LangEnum, PageView } from '@utmb/types/index';
import { i18n } from '../../next-i18next.config';
import { fetchAPI } from '@utmb/utils/utmb';
import { IncomingMessage } from 'http';
import isMobile from '@utmb/utils/is-mobile';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Section404 } from '@utmb/features/section-404/Section404';

interface Error {
    statusCode: number;
}
interface PageProps extends PageDefaultProps, PageView, Error {}

const Error: NextPage<PageProps> = ({
    event,
    eventsTopBar,
    footer,
    isMobile,
    mobileBottomBar,
    navigation,
    simpleMenu,
    seo,
    partners,
    languages,
    contentLocale,
    host,
    statusCode,
}): ReactElement => {
    return (
        <PageProviders>
            <Layout
                {...seo}
                contentLocale={contentLocale}
                isMobile={isMobile}
                eventsTopBar={eventsTopBar}
                mobileBottomBar={mobileBottomBar}
                navigation={navigation}
                simpleMenu={simpleMenu}
                footer={footer}
                host={host}
                landing={true}
                event={event}
                partners={partners}
                languages={languages}
                menuLogoIsDark={true}
            >
                {statusCode === 404 ? <Section404 /> : <Section500 />}
            </Layout>
        </PageProviders>
    );
};

export const getServerSideProps: GetServerSideProps = async ({ res, locale, defaultLocale, req }) => {
    try {
        const lang: LangEnum = (locale || defaultLocale || i18n.defaultLocale) as LangEnum;
        const translations = await serverSideTranslations(lang, ['common']);
        const statusCode = res.statusCode;
        const host = req.headers.host;
        // Global
        const pageProps = await fetchAPI(req as IncomingMessage, '/content/global', { lang });
        pageProps.isMobile = isMobile(req);

        // Page Content
        const page = await fetchAPI(req, '/content/home', { lang });

        if (!page) return { notFound: true };

        const { locale: contentLocale } = page;
        return {
            props: {
                contentLocale,
                host,
                pageProps,
                statusCode,
                ...translations,
            },
        };
    } catch (error) {
        return {
            notFound: true,
        };
    }
};

export default Error;
